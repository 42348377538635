import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import AnimatedPage from "../components/AnimatedPage";
import axios from "axios";
import swal from "sweetalert";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import CloseIcon from "@mui/icons-material/Close";

function Login() {
    const [err, setErr] = useState(null);
    const login = sessionStorage.getItem("user");
    const [notify, setNotify] = useState(JSON.parse(localStorage.getItem("notify")));
    const [setting, setSetting] = useState(JSON.parse(localStorage.getItem("setting")));
    const {
        register,
        handleSubmit,
        setError,
        formState: { errors }
    } = useForm();
    const navigate = useNavigate();
    useEffect(() => {
        if (login) {
            navigate("/");
        }
        axios.get(`${process.env.REACT_APP_API_URL}/setting/get`, {}).then((res) => {
            if (setting != res.data.data[0]) {
                setSetting(res.data.data[0]);
                localStorage.setItem("setting", JSON.stringify(res.data.data[0]));
            }
        });
        axios.get(`${process.env.REACT_APP_API_URL}/auth/getnotify`, {}).then((res) => {
            if (notify != res.data.data) {
                setNotify(res.data.data);
                localStorage.setItem("notify", JSON.stringify(res.data.data));
            }
        });
    }, []);
    const onSubmit = async (data) => {
        const regex = /^[a-zA-Z0-9]+$/;
        if (data.username.length < 6) {
            setError("username", {
                type: "minLength",
                message: "Tên đăng nhập tối thiểu 6 kí tự"
            });
            return false;
        }
        if (!regex.test(data.username)) {
            setError("username", {
                type: "minLength",
                message: "Tên đăng nhập không chứa khoảng trắng và ký tự đặc biệt"
            });
            return false;
        }
        if (data.password.length < 6) {
            setError("password", {
                type: "minLength",
                message: "Mật khẩu tối thiểu 6 kí tự"
            });
            return false;
        }
        axios
            .post(`${process.env.REACT_APP_API_URL}/auth/login`, {
                username: data.username.toLowerCase(),
                password: data.password
            })
            .then((res) => {
                swal({
                    title: "Thông báo",
                    text: "Đăng nhập thành công",
                    icon: "success",
                    buttons: "OK"
                }).then(() => {
                    sessionStorage.removeItem("user");
                    localStorage.removeItem("profile");
                    localStorage.removeItem("data");
                    sessionStorage.setItem("user", res.data.data);
                    localStorage.setItem("password", data.password);
                    navigate("/?notify");
                });
            })
            .catch((err) => setErr(err.response.data.message ? err.response.data.message : "Tên đăng nhập hoặc mật khẩu không chính xác!"));
    };
    const [showPassword, setShowPassword] = useState(false);
    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };
    return (
        <div className="login">
            <div onClick={() => navigate("/")} className="login-return">
                <CloseIcon className="login-return-i" />
            </div>
            <img src={require("../../static/logo.png")} style={{ height: "55px", width: "auto", margin: "40px 0 0" }} />
            <AnimatedPage>
                <form className="form-lg" onSubmit={handleSubmit(onSubmit)}>
                    <h1>Đăng nhập</h1>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <div style={{ flex: "1" }}>
                            <img alt="" src={require("../../static/i_spr_h.png")} style={{ height: "50px" }} />
                            <div style={{ margin: "5px 0" }}>Real Madrid</div>
                        </div>
                        <div style={{ flex: "1" }}>
                            <img alt="" src={require("../../static/i_spr_q.png")} style={{ height: "50px" }} />
                            <div style={{ margin: "5px 0" }}>Chelsea</div>
                        </div>
                        <div style={{ flex: "1" }}>
                            <img alt="" src={require("../../static/i_spr_s.png")} style={{ height: "50px" }} />
                            <div style={{ margin: "5px 0" }}>Inter Milan</div>
                        </div>
                    </div>
                    <div style={{ margin: "10px 0", fontSize: "110%" }}>Đối Tác Hợp Tác Chính Thức</div>
                    <img alt="" src={require("../../static/decorate.png")}/><br/><br/>
                    <div className="inputs">
                        <div className="input">
                            <img alt="" src={require("../../static/i_user.png")} />
                            <input type="text" className="ip-lg" {...register("username", { required: true })} placeholder="Tên đăng nhập" />
                            {errors.username ? <p>{errors.username.message}</p> : null}
                        </div>
                        <div className="input">
                            <img alt="" src={require("../../static/i_pasw.png")} />
                            <input type={showPassword ? "text" : "password"} className="ip-lg" {...register("password", { required: true })} placeholder="Mật khẩu" />
                            {showPassword ? <Visibility onClick={toggleShowPassword} /> : <VisibilityOff onClick={toggleShowPassword} />}
                            {errors.password ? <p>{errors.password.message}</p> : null}
                        </div>
                    </div>
                    {err ? <p>{err}</p> : null}
                    <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", padding: "0 0 20px", color: "#fff" }}>
                        <Link to="/register">Đăng ký</Link>
                        <Link to="/service">Quên mật khẩu</Link>
                    </div>
                    <button className="btn-red-big" type="submit">
                        ĐĂNG NHẬP
                    </button>
                    <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", padding: "50px 0 20px", color: "#fff" }}>
                        <Link
                            to="/"
                            style={{ textDecoration: "none", display: "flex", alignItems: "center", gap: "8px", justifyContent: "center", color: "#4d595e", fontSize: "0.4rem" }}>
                            <img alt="" src={require("../../static/i_quit.png")} style={{ height: "24px" }} />
                            TRANG CHỦ
                        </Link>
                        <Link
                            to="/service"
                            style={{ textDecoration: "none", display: "flex", alignItems: "center", gap: "8px", justifyContent: "center", color: "#4d595e", fontSize: "0.4rem" }}>
                            <img alt="" src={require("../../static/i_service.png")} style={{ height: "24px" }} />
                            CSKH
                        </Link>
                    </div>
                </form>
            </AnimatedPage>
        </div>
    );
}
export default Login;
