import React from "react";
import { Link, useNavigate } from "react-router-dom";
import SettingsIcon from "@mui/icons-material/Settings";
import NotificationsIcon from "@mui/icons-material/Notifications";

const Header = ({ profile }) => {
    const navigate = useNavigate();
    return (
        <div className="header">
            <div className="logo">
                <Link to="/">
                    <img alt="" src={require("../../static/logo.png")} />
                </Link>
            </div>
            <div className="header-right">
                {profile ? (
                    <>
                        <div className="header-profile" onClick={() => navigate("/profile")}>
                            {/*<div style={{ color: "#777" }}>{profile?.username}</div>*/}
                            <div style={{ fontWeight: "bold", color: "#ed5b29" }}>{Math.floor(profile?.money).toLocaleString("vi-VN")}</div>
                        </div>
                        <div style={{ display: "flex", marginLeft: "8px" }} onClick={() => navigate("/profile")}>
                            <i class="d20_account_account" style={{ fontSize: "25px" }}></i>
                        </div>
                    </>
                ) : (
                    <>
                        <Link to="/login" className="btn-login" style={{ marginBottom: "0" }}>
                            Đăng nhập
                        </Link>
                        <Link to="/register" className="btn-register" style={{ marginBottom: "0" }}>
                            Đăng ký
                        </Link>
                    </>
                )}
            </div>
        </div>
    );
};

export default Header;
